<template>
  <div class="AuxiliaryLine " :style="customStyle()"></div>
</template>

<script>


export default {
 name: 'AuxiliaryLine',
  props:{
    /**
     *    
     * dotted  点状 
     * solid   实线
     * double  双线
     * dashed; 虚线  
     * 
     **/
    // border_style:{
    //   type:String,
    // }
    AuxiliaryLineConf:{
      type:Object,
      default:()=>{
        return {isPadding:false,Color:"#eeeeee",borderStyle:"solid"}
      },
    }

  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {


    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /**    */
    customStyle(){
      let padlr = this.AuxiliaryLineConf.isPadding?'10px':"0px"

      return{
      'height':'1px',
      'border-top-style':this.AuxiliaryLineConf.borderStyle,
      'border-top-width':'1px',
      'border-top-color':this.AuxiliaryLineConf.Color,
      'margin': '0 ' + padlr
      }
    
    },
  },
  /** 监听数据变化   */
  watch: {},
  /**  实例创建完成之后  */
  created() {},
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">

.AuxiliaryLine{
 }
</style>
